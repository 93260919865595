import React from 'react';

function ResponseOutput({ imageUpload, imageURL, responseData }) {
  return (
    <div className="ResponseData">
      <img src={imageUpload || imageURL} height='200' alt='' />
        <h2>Rezultati analize:</h2>
      <ul>
        {responseData.map((item, i) => (
          <li key={i}>{item} </li>
        ))}
      </ul>
    </div>
  );
}

export default ResponseOutput;
