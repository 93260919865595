import ResponseError from './ResponseError';
import ResponseOutput from './ResponseOutput';

function InputFormComponent({
                                imageURL,
                                responseData,
                                handleImageUrl,
                                handleSubmit,
                                handleFileRead,
                                imageUpload,
                                errorData,
                            }) {
    return (
        <div className="Form">
            <div className="FormWrapper">
                <div className="FormContainer">
                    <h2>Dodajte URL slike koju želite provjeriti</h2>
                    <form onSubmit={handleSubmit}>
                        <input className="UrlInput" type='text' value={imageURL} onChange={handleImageUrl}/>
                        <input className="InputSubmit" type='submit' value='Provjeri' />
                    </form>

                    <div className="UploadInputWrapper">
                        <h2>ili odaberite datoteku sa računala</h2>
                        <label className="UploadLabel" for="Photo">Dodaj...</label>
                        <input id="Photo" className="UploadInput" onChange={handleFileRead} type='file' accept='image/*'/>
                    </div>
                </div>



                {responseData && (
                    <ResponseOutput
                        imageUpload={imageUpload}
                        imageURL={imageURL}
                        responseData={responseData}
                    />
                )}

                {errorData && <ResponseError errorData={errorData}/>}
            </div>
        </div>
    );
}

export default InputFormComponent;
