import React from 'react';

function ResponseError({ errorData }) {
  return (
    <div>
      <p className="Error">{errorData}</p>
    </div>
  );
}

export default ResponseError;
